/* ------------------------------------*\
   CSS Custom Properties to define base breakpoints
\*------------------------------------ */
.burgerMenu {
  display: block; }

.burgerButton {
  width: var(--GW-BURGER-MENU-BUTTON-SIZE);
  height: var(--GW-BURGER-MENU-BUTTON-SIZE); }

.burgerButtonIcon {
  font-size: calc(var(--GW-BURGER-MENU-BUTTON-SIZE) * 0.75); }

.burgerSidebar {
  position: absolute;
  top: 0;
  left: 0;
  transition: all var(--GW-ANIMATION-DURATION) ease 0s;
  height: var(--GW-BURGER-SIDEBAR-HEIGHT);
  background: var(--GW-BURGER-SIDEBAR-BACKGROUND-COLOR);
  z-index: var(--GW-BURGER-SIDEBAR-Z-INDEX);
  width: var(--GW-BURGER-SIDEBAR-WIDTH);
  overflow: auto; }
  .burgerSidebar.isClosed {
    visibility: hidden;
    width: 0; }

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--GW-BURGER-SIDEBAR-BACKDROP-BACKGROUND-COLOR);
  z-index: var(--GW-Z-INDEX-3);
  transition: var(--GW-ANIMATION-DURATION);
  opacity: 1;
  visibility: visible; }
  .backdrop.isClosed {
    opacity: 0;
    visibility: hidden; }

.sidebarItem {
  width: 100%;
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  text-decoration: none;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: var(--GW-BURGER-SIDEBAR-COLOR);
  font-size: var(--GW-FONT-SIZE-BODY);
  line-height: var(--GW-LINE-HEIGHT-BODY); }
  .sidebarItem.sidebarItemInteractive {
    cursor: pointer; }
    .sidebarItem.sidebarItemInteractive:hover {
      background-color: var(--GW-BURGER-SIDEBAR-BACKGROUND-COLOR-HOVER); }
    .sidebarItem.sidebarItemInteractive:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--GW-BURGER-SIDEBAR-FOCUS);
      color: var(--GW-BURGER-SIDEBAR-COLOR);
      background-color: var(--GW-BURGER-SIDEBAR-FOCUS); }
    .sidebarItem.sidebarItemInteractive:active {
      background-color: var(--GW-BURGER-SIDEBAR-BACKGROUND-COLOR-ACTIVE); }

.sidebarSeparator {
  border-top: 1px solid var(--GW-SEPARATOR-COLOR);
  margin: 0.5rem 1.25rem; }

.sidebarHeader {
  padding-top: var(--GW-LAYOUT-4);
  justify-content: space-between; }
  .sidebarHeader .menu {
    font-size: var(--GW-FONT-SIZE-H3);
    line-height: var(--GW-LINE-HEIGHT-H3);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD); }

.burgerAppSwitcherButtonTitle {
  min-width: 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: var(--GW-SPACING-4); }

/*# sourceMappingURL=BurgerMenu.module.css.map */