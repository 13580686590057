.rightSideBar {
  background-color: var(--GW-RIGHT-SIDEBAR-SIDE-BACKGROUND-COLOR);
  box-shadow: var(--GW-SHADOW-SIDE);
  overflow: hidden; }

.bar {
  background-color: var(--GW-RIGHT-SIDEBAR-BAR-BACKGROUND-COLOR);
  box-shadow: var(--GW-SHADOW-SIDE); }

.icons {
  flex: 1; }

.footer {
  text-align: center;
  padding: var(--GW-SPACING-3);
  border-top: var(--GW-BORDER-COLOR) 1px solid; }

.hiddenSide {
  display: none; }

.contentWrapper {
  transition: width var(--GW-ANIMATION-DURATION);
  width: var(--GW-RIGHT-SIDEBAR-SIDE-WIDTH);
  overflow: hidden; }

.contentWrapperInner {
  height: 100%;
  width: var(--GW-RIGHT-SIDEBAR-SIDE-WIDTH); }

.contentWrapperCollapsed {
  width: 0; }

/*# sourceMappingURL=RightSideBar.module.css.map */